import React, { useState } from "react";
import logo from "../../assets/logo/1.png";
import "./NavbarStyles.css";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import whitelogo from "../../assets/logo/perspective logo white.png";
import { Link, useLocation } from "react-router-dom";

import { navItems } from "./NavItems";
import Dropdown from "./Dropdown";
import { FaAngleDown } from "react-icons/fa";

// import downarrow from "../../assets/icon/down.svg";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [bgnav, setBgnav] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [sub, setSub] = useState(false);

  const handleNav = () => setNav(!nav);
  const location = useLocation().pathname;

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setBgnav(true);
    } else {
      setBgnav(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div>
      <div className={bgnav ? "nav-header active" : "nav-header"}>
        <Link to={"/"}>
          <div className={bgnav ? "logo1" : "logo"}>
            <div className={bgnav ? "no-logo" : "yes-logo"}>
              <img src={logo} alt="Company logo gulf shore" />
            </div>
            <div className={bgnav ? "yes-logo" : "no-logo"}>
              <img
                style={{ width: "120px" }}
                src={whitelogo}
                alt="white company logo gulf shore"
              />
            </div>
          </div>
        </Link>
        <ul className={"nav-menu"}>
          {navItems.map((item) => {
            if (item.title === "SERVICES") {
              return (
                <li
                  key={item.id}
                  onClick={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link
                    className={
                      (location === "/" && !bgnav) || bgnav ? "white" : "black"
                    }
                    to={item.path}
                  >
                    {item.title}

                    <FaAngleDown
                      color={
                        (location === "/" && !bgnav) || bgnav
                          ? "white"
                          : "black"
                      }
                      className="down-icon"
                    />
                  </Link>

                  {dropdown && <Dropdown />}
                </li>
              );
            }
            return (
              <li key={item.id} className={item.cName}>
                <Link
                  className={
                    (location === "/" && !bgnav) || bgnav ? "white" : "black"
                  }
                  to={item.path}
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>

        <div className="hamburger" onClick={handleNav}>
          <HiOutlineMenuAlt4
            color={
              (useLocation().pathname === "/" && !bgnav) || bgnav
                ? "white"
                : "black"
            }
            size={40}
          />
        </div>
        <div className={nav ? "mobile-menu active" : "mobile-menu"}>
          <ul className="mobile-nav">
            <Link className="mobile-link" to={"/"}>
              <li onClick={handleNav}>HOME</li>
            </Link>
            <li className="mobile-link" onClick={() => setSub(!sub)}>
              SERVICES
              <FaAngleDown className="down-icon" />
              <ul
                className={sub ? "sub-active" : "services-sub"}
                onClick={handleNav}
              >
                <li>
                  <Link className="mobile-link" to={"/oilandgas"}>
                    ELECTRICAL SERVICES
                  </Link>
                </li>
                <li>
                  <Link className="mobile-link" to={"/engineering"}>
                    PLUMBING SERVICES
                  </Link>
                </li>
                <li>
                  <Link className="mobile-link" to={"/pre-engineered"}>
                    HVAC SERVICES
                  </Link>
                </li>
                <li>
                  <Link
                    className="mobile-link"
                    to={"/off-shore-on-shore-rental"}
                  >
                    CIVIL SERVICES
                  </Link>
                </li>
              </ul>
            </li>
            <Link className="mobile-link" to={"/aboutus"}>
              <li onClick={handleNav}>ABOUT US</li>
            </Link>
            <Link className="mobile-link" to={"/contactus"}>
              <li onClick={handleNav}>CONTACT US</li>
            </Link>
            <div
              style={{
                width: "100%",
                justifyContent: "space-around",
                display: "flex",
              }}
            >
              <IoMdClose size={40} onClick={handleNav} />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
