import React from "react";
import Navbar from "./components/navbar/Navbar";
import { BrowserRouter } from "react-router-dom";

import AnimatedRoutes from "./components/AnimatedRoutes";

import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <AnimatedRoutes />

      <Footer />
    </BrowserRouter>
  );
}

export default App;
