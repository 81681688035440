import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoWhite from "../../assets/logo/perspective logo white.png";
import "./AboutUs.css";
import VisionSection from "../vision/VisionSection";
import Certification from "../../components/certification/Certification";
import { motion } from "framer-motion";

function AboutUs() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <Container>
        <Row>
          <Col>
            <div className="oil-banner7">
              <div className="oil-title7">
                <div className="about-us-heading">
                  <img src={logoWhite} style={{ width: "400px" }} alt="" />
                </div>
              </div>
              <div className="overlay7"></div>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            <div className="about-us-decs">
              We are leading electromechanical contractor established to meet
              the market requirements and work on serving its customers as
              appropriate and required. We value our esteemed customers and
              strongly believe in accurate, quality and prompt service in each
              and every aspects. We are commited to delivering high quality cost
              effective services and will strive to implement long-term
              relationships with our clients based on quality, health and
              safety, timely service and by anticipation of their needs. Our
              focus is on complete solution for the customers through supply,
              installation, Testing Commissioning and maintenance of all kinds
              of electrical and mechanical equipment and components.
            </div>
          </div>
        </Row>
      </Container>

      <VisionSection />
    </motion.div>
  );
}

export default AboutUs;
