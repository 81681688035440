import React from "react";
import bg1 from "../../assets/background/1.jpg";
import bg2 from "../../assets/background/2.jpg";
import bg3 from "../../assets/background/3.jpg";
import bg4 from "../../assets/background/4.jpg";
import contentimg1 from "../../assets/content-img/1.jpg";
import contentimg2 from "../../assets/content-img/2.jpg";
import electicpost from "../../assets/icon/tower.png";
import pipe from "../../assets/icon/pipe.png";
import temp from "../../assets/icon/temp.png";
import crane from "../../assets/icon/crane.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { motion } from "framer-motion";
import Slider from "react-slick";
import { FaCircleArrowRight } from "react-icons/fa6";
import "./Home.css";
import Carousel from "react-bootstrap/Carousel";
import { Col, Container, Row } from "react-bootstrap";
import video from "../../assets/bg-video.mp4";
import client1 from "../../assets/client-logo/image 20.png";
import client2 from "../../assets/client-logo/image 21.png";
import client3 from "../../assets/client-logo/image 22.png";
import client4 from "../../assets/client-logo/image 23.png";
import client5 from "../../assets/client-logo/image 24.png";
import client6 from "../../assets/client-logo/image 25.png";
import client7 from "../../assets/client-logo/image 26.png";

import VisionSection from "../vision/VisionSection";
import { Link } from "react-router-dom";
// import { TypeAnimation } from "react-type-animation";

const clientImage = [
  {
    img: client1,
    id: "11",
  },
  {
    img: client2,
    id: "12",
  },
  {
    img: client3,
    id: "13",
  },
  {
    img: client4,
    id: "14",
  },
  {
    img: client5,
    id: "15",
  },
  {
    img: client6,
    id: "16",
  },
  {
    img: client7,
    id: "17",
  },
];

function Home() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <div className="home">
        <div className="hero">
          {/* <video autoPlay loop muted playsInline id="video">
            <source src={video} type="video/mp4" />
          </video> */}
          <div className="home-slider">
            <Carousel fade>
              <Carousel.Item interval={3000}>
                <img
                  style={{
                    width: "100%",
                    height: "100vh",
                    objectFit: "cover",
                  }}
                  src={bg1}
                  text="First slide"
                  alt="oil rig in the sea"
                />

                {/* <div className="slider-content">
                  <div className="slider-heading">
                    when services matters your choice is simple
                  </div>

                  <button className="slider-btn">CONTACT US</button>
                </div> */}
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img
                  style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                  src={bg2}
                  text="Second slide"
                  alt="oil rig in the sea"
                />
                {/* <div className="slider-content">
                  <div className="slider-heading">
                    GULF SHORE GROUP we provide high quality service
                  </div>
                  <button className="slider-btn">CONTACT US</button>
                </div> */}
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img
                  style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                  src={bg3}
                  text="Third slide"
                  alt="oil rig in the sea"
                />
                {/* <div className="slider-content">
                  <div className="slider-heading">
                    when services matters your choice is simple
                  </div>
                  <button className="slider-btn">CONTACT US</button>
                </div> */}
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img
                  style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                  src={bg4}
                  text="Fourth slide"
                  alt="oil rig in the sea"
                />
                {/* <div className="slider-content">
                  <div className="slider-heading">
                    GULF SHORE GROUP 17 years of Engineering Experience
                  </div>
                  <button className="slider-btn">CONTACT US</button>
                </div> */}
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="overlay10"></div>
          <div className="hero-slider-content">
            <Carousel pause={false}>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    when services matters your choice is simple
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    we provide high quality service
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    when services matters your choice is simple
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    years of Engineering Experience
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        {/* <TypeAnimation
                      sequence={[
                        "when services matters your choice is simple",
                        1000,
                      ]}
                      wrapper="span"
                      speed={50}
                      style={{ fontSize: "2em", display: "inline-block" }}
                      repeat={Infinity}
                    /> */}

        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-1-img-section">
                <Carousel pause={false}>
                  <Carousel.Item interval={2000}>
                    <div className="content-1-img">
                      <img src={contentimg1} alt="oil pipe" />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={2000}>
                    <div className="content-1-img">
                      <img src={contentimg2} alt="oil pipe" />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>

            <Col>
              <div className="content-1">
                <div className="content-1-details">
                  <h1 className="heading">about us</h1>
                </div>
                <div className="content-1-details">
                  <p className="para">
                    <b>
                      We are leading electromechanical contractor established to
                      meet the market requirements and work on serving its
                      customers as appropriate and required.
                    </b>
                    We value our esteemed customers and strongly believe in
                    accurate, quality and prompt service in each and every
                    aspects. We are commited to delivering high quality cost
                    effective services and will strive to implement long-term
                    relationships with our clients based on quality, health and
                    safety, timely service and by anticipation of their needs.
                    Our focus is on complete solution for the customers through
                    supply, installation, Testing Commissioning and maintenance
                    of all kinds of electrical and mechanical equipment and
                    components.
                  </p>
                </div>
                <div className="content-1-details">
                  <Link to={"aboutus"}>
                    <button className="content-btn">MORE INFO</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="section-heading">
              <div className="heading-desc">An overview of what we do.</div>
              <div className="heading-text">Our Services</div>
            </div>
          </Row>
          <Row>
            <Col lg={6} md={6}>
              <div className="box">
                <div>
                  <img src={electicpost} style={{ width: "50px" }} alt="" />
                </div>
                <div className="box-heading">ELECTRICAL SERVICES</div>
                <ul className="box-list">
                  <li>Supply & Installation of MDB's, LV Panels.</li>
                  <li>Supply & Installation of SMDB's /Final DB's.</li>
                  <li>
                    Supply & Installation of Capacitor Banks, Diesel Generators
                    & ATS.
                  </li>
                  <li>
                    Supply & Installation of Earthing and Lightning Protection
                    Systems.
                  </li>
                  <li>
                    Supply & Installation of Interior and Exterior Lighting
                    Systems.
                  </li>
                  <li>
                    Supply & Installation of Landscaping Lighting and Façade
                    lighting.
                  </li>

                  <li>Main Cabling, Glanding and Termination.</li>
                  <li>Wiring of Lighting and Power distribution system.</li>
                  <li>
                    Lighting Controls for residential and commercial projects.
                  </li>
                  <li>
                    Supply & Installation of Electrical Containments such as
                    conduiting, cable tray & cable trunking.
                  </li>
                  <li>Supply & Installation of all mechanical equipment's.</li>
                  <li>Supply & installation of Low current systems.</li>
                  <li>Supply & Installation of Solar Systems.</li>
                </ul>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="box">
                <div>
                  <img src={pipe} style={{ width: "50px" }} alt="" />
                </div>
                <div className="box-heading">PLUMBING SERVICES</div>
                <ul className="box-list">
                  <li>
                    Supply & Installation of Commercial, Industrial & Domestic
                    Hot and Cold Water Systems
                  </li>
                  <li>
                    Supply, Installation, Repairing& Maintenance of Commercial
                    Plumbing drainage Systems
                  </li>
                  <li>
                    Supply, Installation, Repairing & Replacing of Sanitary
                    wares
                  </li>
                  <li>
                    Supply, Installation & Repairing of Transfer Pump, Booster
                    Pump & Sump Pumps.
                  </li>
                  <li>
                    Supply, Installation of Electrical and Solar water heater
                    system with circulation Pumps.
                  </li>
                  <li>Supply, Installation of Water Filtration system</li>

                  <li>
                    Swimming pool and landscaping installation and maintenance.
                  </li>
                  <br />
                  <br />
                  <br />
                  <br />
                </ul>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="box">
                <div>
                  <img src={temp} style={{ width: "50px" }} alt="" />
                </div>
                <div className="box-heading">HVAC SERVICES</div>
                <ul className="box-list">
                  <li>
                    All type of Air Conditioning System Centralized,
                    Wall-Mounted, Split System, CC, Ducted.
                  </li>
                  <li> Package Unit systems</li>
                  <li>
                    Chilled water system, chiller plants and cooling towers.
                  </li>
                  <li>
                    Installation of all types of Gl and Pl ducting and
                    insulation.
                  </li>
                  <li>Variable Refrigerant Flow systems(VRV/VRF)</li>
                  <li>Ventilation System and FAHU systems.</li>
                  <li>
                    Inspection, Surveys & Reports of Existing Installations.
                  </li>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </ul>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="box">
                <div>
                  <img src={crane} style={{ width: "50px" }} alt="" />
                </div>
                <div className="box-heading">CIVIL SERVICES</div>
                <ul className="box-list">
                  <li>
                    Supply & Installation of Block work (Insulation, Solid
                    block, Hollow block and AAC block .
                  </li>
                  <li>
                    Supply & Apply of all types of Plaster work (Manual mix,
                    Ready mix) As per Specs).
                  </li>
                  <li>Supply & Apply of all types of tiles</li>
                  <li>
                    Supply & Apply of all types of False ceiling( Gypsum/60x60
                    Ceiling Tile/Cement board).
                  </li>
                  <li>
                    Supply & Apply of all types of Light Partitions(
                    Gypsum/Cement board).
                  </li>
                  <li>
                    Supply & Apply painting ( internal and External painting )
                  </li>
                  <li>
                    Supply & Apply of all types of interlock and kerbstone.{" "}
                  </li>
                  <li>Supply & Apply for landscaping works.</li>
                  <li>Supply & Apply Swimming pool works.</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        <VisionSection />

        {/* <div>
          <div className="home-section4">
            <div className="section-heading">
              <div className="heading-text">Take a look at our clients</div>
              <div className="heading-desc">
                We did a great job with these companies. You can be next to work
                with!
              </div>
            </div>

            <div className="client-slider">
              <Slider className="slider-css" {...settings}>
                {clientImage.map((i) => (
                  <div className="slider" key={i.id}>
                    <img src={i.img} alt="" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div> */}
      </div>
    </motion.div>
  );
}

export default Home;
