import React from "react";
import "./Pre-engineering.css";
import { Col, Container, Row } from "react-bootstrap";

import temp from "../../assets/icon/temp.png";
import VisionSection from "../../components/vision/VisionSection";
import Certification from "../../components/certification/Certification";

import { motion } from "framer-motion";

function PreEngineering() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <div className="services-section3">
        <Container>
          <Row>
            <Col>
              <div className="oil-banner3">
                <div className="oil-title3">
                  <div>
                    {" "}
                    <img src={temp} style={{ width: "50px" }} alt="" />
                  </div>
                  <div>HVAC SERVICES</div>
                </div>
                <div className="overlay3"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="services-box3">
                <div>
                  {" "}
                  All type of Air Conditioning System Centralized, Wall-Mounted,
                  Split System, CC, Ducted.
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box3">
                <div>Package Unit systems</div>
              </div>
            </Col>
            <Col>
              <div className="services-box3">
                <div>
                  Chilled water system, chiller plants and cooling towers.
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box3">
                <div>
                  Installation of all types of Gl and Pl ducting and insulation.
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box3">
                <div>Variable Refrigerant Flow systems(VRV/VRF)</div>
              </div>
            </Col>
            <Col>
              <div className="services-box3">
                <div>Ventilation System and FAHU systems.</div>
              </div>
            </Col>{" "}
            <Col>
              <div className="services-box3">
                <div>
                  {" "}
                  Inspection, Surveys & Reports of Existing Installations.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <VisionSection />
    </motion.div>
  );
}

export default PreEngineering;
