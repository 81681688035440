export const navItems = [
  {
    id: 11,
    title: "HOME",
    path: "/",
    cName: "nav-item",
  },
  {
    id: 2,
    title: "SERVICES",

    cName: "nav-item",
  },
  {
    id: 3,
    title: "ABOUT US",
    path: "/aboutus",
    cName: "nav-item",
  },
  {
    id: 4,
    title: "CONTACT US",
    path: "/contactus",
    cName: "nav-item",
  },
];

export const servicesDropdown = [
  {
    id: 12,
    title: "ELECTRICAL SERVICES",
    path: "/oilandgas",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "PLUMBING SERVICES",
    path: "/engineering",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "HVAC SERVICES",
    path: "/pre-engineered",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "CIVIL SERVICES",
    path: "/off-shore-on-shore-rental",
    cName: "submenu-item",
  },
  // {
  //   id: 5,
  //   title: "maintenance & refurbishment",
  //   path: "/maintanance-refurbishment",
  //   cName: "submenu-item",
  // },
  // {
  //   id: 6,
  //   title: "load testing and inspections",
  //   path: "/load-testing-and-inspection",
  //   cName: "submenu-item",
  // },
];
