import React from "react";
import "./Engineering.css";
import { Col, Container, Row } from "react-bootstrap";

import pipe from "../../assets/icon/pipe.png";
import VisionSection from "../../components/vision/VisionSection";
import Certification from "../../components/certification/Certification";
import { motion } from "framer-motion";

function Engineering() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <div className="services-section2">
        <Container>
          <Row>
            <Col>
              <div className="oil-banner2">
                <div className="oil-title2">
                  <div>
                    <img src={pipe} style={{ width: "50px" }} alt="" />
                  </div>
                  <div>PLUMBING SERVICES</div>
                </div>
                <div className="overlay2"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="services-box2">
                <div>
                  {" "}
                  Supply & Installation of Commercial, Industrial & Domestic Hot
                  and Cold Water Systems
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box2">
                <div>
                  Supply, Installation, Repairing& Maintenance of Commercial
                  Plumbing drainage Systems
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box2">
                <div>
                  {" "}
                  Supply, Installation, Repairing & Replacing of Sanitary wares
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box2">
                <div>
                  {" "}
                  Supply, Installation & Repairing of Transfer Pump, Booster
                  Pump & Sump Pumps.
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box2">
                <div>
                  {" "}
                  Supply, Installation of Electrical and Solar water heater
                  system with circulation Pumps.
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box2">
                <div>Supply, Installation of Water Filtration system</div>
              </div>
            </Col>
            <Col xl="4" lg="6" md="6">
              <div className="services-box2">
                <div>
                  {" "}
                  Swimming pool and landscaping installation and maintenance.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <VisionSection />
    </motion.div>
  );
}

export default Engineering;
