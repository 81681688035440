import React from "react";
import "./OffShoreRental.css";
import { Col, Container, Row } from "react-bootstrap";

import crane from "../../assets/icon/crane.png";
import VisionSection from "../../components/vision/VisionSection";
import Certification from "../../components/certification/Certification";
import { motion } from "framer-motion";

function OffShoreRental() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <div className="services-section4">
        <Container>
          <Row>
            <Col>
              <div className="oil-banner4">
                <div className="oil-title4">
                  <div>
                    <img src={crane} style={{ width: "50px" }} alt="" />
                  </div>
                  <div>CIVIL SERVICES</div>
                </div>
                <div className="overlay4"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="services-box4">
                <div>
                  {" "}
                  Supply & Installation of Block work (Insulation, Solid block,
                  Hollow block and AAC block .
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box4">
                <div>
                  {" "}
                  Supply & Apply of all types of Plaster work (Manual mix, Ready
                  mix) As per Specs).
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box4">
                <div>Supply & Apply of all types of tiles</div>
              </div>
            </Col>
            <Col>
              <div className="services-box4">
                <div>
                  {" "}
                  Supply & Apply of all types of False ceiling( Gypsum/60x60
                  Ceiling Tile/Cement board).
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box4">
                <div>
                  {" "}
                  Supply & Apply of all types of Light Partitions( Gypsum/Cement
                  board).
                </div>
              </div>
            </Col>
            <Col>
              <div className="services-box4">
                <div>
                  Supply & Apply painting ( internal and External painting )
                </div>
              </div>
            </Col>
            <Col xl="4" lg="6" md="6">
              <div className="services-box4">
                <div>
                  {" "}
                  Supply & Apply of all types of interlock and kerbstone.
                </div>
              </div>
            </Col>
            <Col xl="4" lg="6" md="6">
              <div className="services-box4">
                <div> Supply & Apply for landscaping works.</div>
              </div>
            </Col>
            <Col xl="4" lg="6" md="6">
              <div className="services-box4">
                <div> Supply & Apply Swimming pool works.</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <VisionSection />
    </motion.div>
  );
}

export default OffShoreRental;
