import React from "react";
import contentLogo from "../../assets/content-logo/vision.png";
import contentLogo2 from "../../assets/content-logo/Frame 5 1.png";
import { motion } from "framer-motion";

import "./VisionSection.css";
function VisionSection() {
  return (
    <div>
      <div className="home-section3">
        <div className="section-heading">
          <div className="heading-desc">
            what are we doing, and why are we doing it.
          </div>
          <div className="heading-text">vission & mission</div>
        </div>
        <div className="section-content3">
          <div className="content3">
            <motion.div
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ ease: "linear", duration: 1.5, repeat: Infinity }}
              className="v-icon"
            >
              <img src={contentLogo} style={{ width: "40px" }} alt="" />
            </motion.div>
            <div className="content3-heading">our vision</div>
            <div className="line"></div>
            <div className="content3-detail">
              To become the market leader in electromechanical contractor sector
              by offering innovative relevent and quality products and services
              with highest standard of professionalism.
            </div>
          </div>

          <div className="content3">
            <motion.div
              animate={{ rotate: [20, -30, 20] }}
              whileTap={{ scale: 0.8, rotate: 90, borderRadius: "100%" }}
              transition={{ ease: "linear", duration: 1.5, repeat: Infinity }}
              className="v-icon"
            >
              <img src={contentLogo2} style={{ width: "40px" }} alt="" />
            </motion.div>
            <div className="content3-heading">our mission</div>
            <div className="line"></div>
            <div className="content3-detail">
              Keypress services and solutions carry on to move forward and
              become the leading company in electromechanical sector, ensure
              value to every customer through quality workmanship and lasting
              solutions delivered by skilled professionals.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionSection;
