import React from "react";
import logoWhite from "../../assets/logo/perspective logo white.png";
import "./Footer.css";
import facebookicon from "../../assets/face.png";
import instagarmicon from "../../assets/instagram.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="footer">
      <div className="logofooter">
        <img src={logoWhite} alt="" />
      </div>
      <div className="menufooter">
        <div>
          <ul className="menu-list">
            <li>
              <Link className="menu-list-link" to={"/"}>
                home
              </Link>{" "}
            </li>
            <li>
              <Link className="menu-list-link" to={"/aboutus"}>
                {" "}
                about us
              </Link>{" "}
            </li>
            <li>
              <Link className="menu-list-link" to={"/contactus"}>
                contact us
              </Link>
            </li>
          </ul>
        </div>

        {/* <div className="iconfooter">
          <div>
            <img src={instagarmicon} alt=" instagram icon" />
          </div>
          <div>
            <img src={facebookicon} alt="facebook icon" />
          </div>
        </div> */}
      </div>
      <div className="contactfooter">
        <div className="footerHeading">address</div>
        <div className="footerDetail">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
          dignissimos perferendis labore, architecto ullam cumque natus
          repudiandae iusto iure minima!
        </div>
        <div className="footerHeading">phone</div>
        <div className="footerDetail">71192119</div>
        <div className="footerHeading">e-mail</div>
        <div className="footerDetail">aaaaaaa@gmail.com</div>
      </div>
    </div>
  );
}

export default Footer;
